<template>
  <div class="spin_container">
    <a-spin />
  </div>

</template>

<script>

export default {
  name: "Loader",
  // setup() {

  // },
  data() {
    return {
    }
  }
}
</script>

<style scoped>
.spin_container{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
