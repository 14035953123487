<template>
  <a-tag v-if="name && color" class="service__work__tag" :color="color">{{
    name
  }}</a-tag>
</template>

<script>
export default {
  props: ['name', 'color'],

  computed: {},
  data () {
    return {}
  }
}
</script>

<style scoped>
.service__work__tag {
  margin-bottom: 10px;
}
</style>
