<template>
  <div class="breadcrumb-header-create-tender">
    <a-breadcrumb>
      <!-- :routes="routes" -->
      <a-breadcrumb-item v-for="b in newBreadcrumbs" :key="b.id">
        <router-link v-if="!b.link" :to="link">{{ b.name }}</router-link>
        <router-link v-if="b.link" :to="b.link">
          {{ b.name }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ newPath }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <template>
      <slot> </slot>
    </template>
    <div class="breadcrumb-label">{{ mainLabelName }}</div>
  </div>
</template>

<script>
export default {
  props: ['mainLabelName', 'breadcrumbs', 'newPath', 'link'],
  data () {
    return {
      newBreadcrumbs: this.breadcrumbs
    }
  }
}
</script>

<style>
.breadcrumb-header-create-tender {
  padding-top: 16px;
  padding-left: 24px;
}

.breadcrumb-label {
  margin: 16px 0px 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
</style>
