<template>
  <div class="customer-no-tenders-container">
    <div v-if="type === 'customerNewTender'">
      <div class="customer-no-tenders-label">У вас ещё тендеров</div>
      <router-link to="/create_tender">
        <Button :buttonClass="classButton">Создать тендер</Button>
      </router-link>
    </div>
    <div v-if="type === 'customerNoTenderRequests'">
      <div class="customer-no-tenders-label">У вас ещё тендеров</div>
      <router-link to="/create_tender">
        <Button :buttonClass="classButton">Создать тендер</Button>
      </router-link>
    </div>
    <div v-if="type === 'waiting'">
      Ожидайте
    </div>
    <div v-if="type === 'personalData'">
      Личные данные не загрузились
    </div>
    <div v-if="type === 'masterFollow'">
      <div class="master-empty-label">У вас ещё тендеров</div>
      <router-link to="/tenders">
        <Button :buttonClass="classButton">Откликнуться на тендер</Button>
      </router-link>
    </div>
    <div v-if="type === 'masterWaiting'">
      Ожидайте
    </div>
    <div v-if="type === 'masterZero'">
      Нет тендеров
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue'

export default {
  props: ['type'],
  components: {
    Button
  },
  data () {
    return {
      classButton: 'red-antd-button'
    }
  }
}
</script>
<style>
.customer-no-tenders-container {
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  border-radius: 8px;
}

.customer-no-tenders-label {
  margin-bottom: 18px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.master-empty-label{
  margin-bottom: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}


</style>
