<template>
  <div class="main-master-wrapper">
    <Breadcrumb
      mainLabelName="Список Тендеров"
      :breadcrumbs="breadcrumbs"
    ></Breadcrumb>

    <a-tabs>
      <a-tab-pane key="1" tab="Сбор заявок">
        <TabViewContainer>
          <Loader  v-if="!this.$store.getters.getAllLoaded" />
          <div v-if="this.$store.getters.getAllLoaded" class="tender_wrapper">
            <EmptyVue v-if="this.$store.getters.getAllTenders.length === 0" type="masterFollow"></EmptyVue>
            <CustomerTenderCell
              v-for="tenderEl in this.$store.getters.getAllTenders"
              :key="tenderEl.id"
              :tender="tenderEl"
              class="announcement-doing-cell"
              link="Tender"
            ></CustomerTenderCell>
          </div>
          <div class="pagination">
            <a-pagination
              :current="currentPageAllTenders"
              :total="this.$store.getters.getAllTotalTenders"
              :showLessItems="true"
              show-less-items
              :hideOnSinglePage="true"
              @change="onChangePagination"
            />
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <!-- <a-tab-pane key="2" tab="Выполняемые">
        <TabViewContainer>
          <AnnouncementCell
            v-for="tenderEl in tenders"
            :key="tenderEl.id"
            :tender="tenderEl"
            class="announcement-doing-cell"
            link="Tender"
          ></AnnouncementCell>
          <div class="pagination">
            <a-pagination
              :current="currentPageAllTenders"
              :total="this.$store.getters.getAllTotalTenders"
              :showLessItems="true"
              show-less-items
              :hideOnSinglePage="true"
              @change="onChangePagination"
            />
          </div>
        </TabViewContainer>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Выполненные">
        <TabViewContainer>
          <AnnouncementCell
            v-for="tenderEl in tenders"
            :key="tenderEl.id"
            :tender="tenderEl"
            class="announcement-doing-cell"
            link="Tender"
          ></AnnouncementCell>
          <div class="pagination">
            <a-pagination
              :current="currentPageAllTenders"
              :total="this.$store.getters.getAllTotalTenders"
              :showLessItems="true"
              show-less-items
              :hideOnSinglePage="true"
              @change="onChangePagination"
            />
          </div>
        </TabViewContainer>
      </a-tab-pane> -->
    </a-tabs>
  </div>
</template>

<script>
import CustomerTenderCell from '@/components/CustomerComponents/CustomerTenderCell.vue'
import TabViewContainer from '@/components/common/TabViewContainer.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import EmptyVue from '@/components/common/EmptyVue.vue'
import Loader from '@/components/common/Loader.vue'

export default {
  components: {
    CustomerTenderCell,
    TabViewContainer,
    Breadcrumb,
    EmptyVue,
    Loader
  },
  mounted () {
    this.$store.dispatch('setAllTenders', {
      array: this.$store.getters.getAllTenders,
      page: this.currentPageAllTenders,
      city: this.$route.query.city,
      tags: this.queryTags ? [this.queryTags] : [],
    })
    // this.$store.dispatch(
    //   "setCustomerTendersStatusOne",
    //   1
    // );
    // this.$store.dispatch(
    //   "setCustomerTendersStatusThree",
    //   1
    // );
  },
  data () {
    return {
      breadcrumbs: [
        {
          id: 1,
          name: 'Главная',
          link: '/main'
        },
        {
          id: 2,
          name: 'Все Тендеры',
          link: '/tenders'
        }
      ],
      currentPageAllTenders: 1
    }
  },
  methods: {
    onChangePagination (current) {

      this.currentPageAllTenders = current
      this.$store.dispatch('setAllTenders', {
        array: this.$store.getters.getMasterActiveTenders,
        page: this.currentPageAllTenders
      })
    }
  }
}
</script>

<style>
.pagination {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 46px;
}
.main-master-wrapper {
  background-color: white;
  width: 100%;
  height: 100%;
}
.profile-label {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.announcement-orders {
  width: 100%;
  height: 100%;
  padding-right: 15%;
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-top: 7px;
}

.announcement-doing-page {
  background-color: #f6f6f6;
  padding-right: 15%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  padding-top: 17px;
}

.announcement-doing-cell {
  margin-top: 10px;
  background-color: white;
}

.tender_wrapper{
  width: 100%;
}
</style>
