var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.isCurrentUser()
      ? 'customer__tender__cell current__user'
      : 'customer__tender__cell'},[_c('div',{staticClass:"customer__tender__cell__date__and__price__container"},[_c('div',{staticClass:"customer__tender__cell__date"},[_vm._v(_vm._s(_vm.tender.created_at))]),_c('div',{staticClass:"customer__tender__cell__date"},[_vm._v(" с "+_vm._s(_vm.tender.planned_start_date)+" по "+_vm._s(_vm.tender.planned_end_date)+" ")]),_c('div',{staticClass:"customer__tender__cell__price"},[_vm._v(" от "+_vm._s(_vm.tender.planned_start_price)+" руб. до "+_vm._s(_vm.tender.planned_end_price)+" руб. ")])]),_c('div',{staticClass:"customer__tender__cell__title__and__status__container"},[_c('div',{staticClass:"customer__tender__cell__title"},[_vm._v(_vm._s(_vm.tender.name))]),_c('div',{staticClass:"customer__tender__cell__pre-word-status"},[_vm._v(" статус: "),_c('span',{style:({
          display: 'inline-block',
          width: '14px',
          textAlign: 'center',
        })}),_c('div',{class:`customer__tender__cell__status__${_vm.tender.status.id}`},[_vm._v(" "+_vm._s(_vm.tender.status.name)+" ")])])]),_c('div',{staticClass:"customer__tender__cell__services__work"},_vm._l((_vm.tender.subtags),function(subtag){return _c('ServiceWork',{key:subtag.subtagId,attrs:{"name":subtag.name,"color":subtag.color}})}),1),_c('div',{staticClass:"customer__tender__cell__description"},[_vm._v(" "+_vm._s(_vm.tender.detailText)+" ")]),_c('div',{staticClass:"customer__tender__cell__button__container"},[_c('router-link',{attrs:{"to":{
        name: `Tender`,
        params: {
          uuid: _vm.tender.uuid,
          type: _vm.type,
        },
      }}},[_c('Button',{staticClass:"red-antd-button"},[_vm._v("Подробнее")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }