<template>
  <div
    :class="
      isCurrentUser()
        ? 'customer__tender__cell current__user'
        : 'customer__tender__cell'
    "
  >
    <div class="customer__tender__cell__date__and__price__container">
      <div class="customer__tender__cell__date">{{ tender.created_at }}</div>
      <!--updated_at or created?  -->
      <div class="customer__tender__cell__date">
        с {{ tender.planned_start_date }} по {{ tender.planned_end_date }}
      </div>
      <div class="customer__tender__cell__price">
        от {{ tender.planned_start_price }} руб. до
        {{ tender.planned_end_price }} руб.
      </div>
    </div>
    <div class="customer__tender__cell__title__and__status__container">
      <div class="customer__tender__cell__title">{{ tender.name }}</div>
      <div class="customer__tender__cell__pre-word-status">
        статус:
        <span
          :style="{
            display: 'inline-block',
            width: '14px',
            textAlign: 'center',
          }"
        ></span>
        <div :class="`customer__tender__cell__status__${tender.status.id}`">
          {{ tender.status.name }}
        </div>
      </div>
    </div>
    <div class="customer__tender__cell__services__work">
      <ServiceWork
        v-for="subtag in tender.subtags"
        :key="subtag.subtagId"
        :name="subtag.name"
        :color="subtag.color"
      />
    </div>
    <div class="customer__tender__cell__description">
      {{ tender.detailText }}
    </div>
    <div class="customer__tender__cell__button__container">
      <router-link
        :to="{
          name: `Tender`,
          params: {
            uuid: tender.uuid,
            type: type,
          },
        }"
      >
        <Button class="red-antd-button">Подробнее</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import ServiceWork from '@/components/common/ServiceWork.vue'
import Button from '@/components/common/Button.vue'
import { getUUID } from '@/helpers/authHelper'

export default {
  components: {
    ServiceWork,
    Button
  },
  props: ['tender', 'link', 'type'],
  data () {
    return {}
  },
  methods: {
    isCurrentUser () {
      if (this.tender.customer.uuid === getUUID()) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.customer__tender__cell {
  display: flex;
  flex-direction: column;
  padding: 26px 20px 40px 30px;
  justify-content: space-around;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  background-color: white;
  border-radius: 3px;
  &.current__user {
    display: flex;
    flex-direction: column;
    padding: 26px 20px 40px 30px;
    box-shadow: 0px 1px 12px -4px #fa491c;
    justify-content: space-around;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    background-color: white;
    border-radius: 3px;
  }
  &__date__and__price__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__date {
    color: #989898;
    text-align: left;
  }

  &__price {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: black;
    display: flex;
  }

  &__title__and__status__container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: black;
  }

  &__pre-word-status {
    display: flex;
    flex-direction: row;
  }

  &__status {
    &__1 {
      color: #7fb56c;
    }
    &__2 {
      color: #b5b46c;
    }
    &__3 {
      color: #fa491c;
    }
  }

  &__services__work {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
  }

  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    margin-top: 12px;
    padding-right: 35%;
  }
  &__button__container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    height: 42px;
    /* width: 160px; */
    background-color: #b12021;
    color: white;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .customer__tender__cell {
    &__date__and__price__container {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__title__and__status__container {
      flex-direction: column;
      justify-content: flex-start;
    }
    &__price {
      text-align: left;
    }
  }
}
</style>
